import React, { lazy, Suspense } from 'react'
import {
    createHashRouter
} from "react-router-dom";


const Index = lazy(() => import('../pages/home'));
const Registration = lazy(() => import('../pages/Registration'));
const Answer = lazy(() => import('../pages/answer'));
const CustomerService = lazy(() => import('../pages/customerService'));
const Map = lazy(() => import('../pages/map'));


const router = createHashRouter([
    { path: '/',element: (<Suspense><Index/></Suspense>) },
    { path: '/registration',element: (<Suspense><Registration/></Suspense>) },
    { path: '/answer',element: (<Suspense><Answer/></Suspense>) },
    { path: '/customerService',element: (<Suspense><CustomerService/></Suspense>) },
    { path: '/map',element: (<Suspense><Map/></Suspense>) },
]);


export default router;